import React from 'react';
import Modal from 'react-modal';
import classes from "./UserInfoModal.module.scss"; // Import custom SCSS styles
import signoutIcon from "../assets/fa-logout.svg";
import menuIcon from "../assets/fa-menu.svg";
import emailIcon from "../assets/fa-email.svg";
import infoIcon from "../assets/fa-info.svg";
import exportIcon from "../assets/fa-export.svg";
import downloadIcon from "../assets/fa-download.svg";

import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import {useRef, useEffect, useState, useContext, useCallback} from "react";
import ValidUserContext from "../authCheck";

const UserInfoModal = ({ isOpen, onClose, companyLink, defaultGroup, defaultUser,  position }) => {
    const customStyles = {
        overlay: {
        zIndex: 1000, // Ensure the overlay has a high z-index
        backgroundColor: 'rgba(0, 0, 0, 0.0)', // Optional: darken the overlay background
        },
        content: {
        top: `${position.y}px`,
        left: `${position.x}px`,
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-100%, 7%)',
        zIndex: 1001, // Ensure the content has a higher z-index
        borderRadius: '15px', // Curved box
        padding: '20px',
        backgroundColor: '#fff', // White background for the modal content
        color: '#000', // Black text color
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add a shadow for better visibility
        },
    };
    const validUserContext = useContext(ValidUserContext);

    const listStyles = {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    };

    const listItemStyles = {
        padding: '0px 0',
        cursor: 'pointer',
        color: '#000', // Explicitly set black text color for list items
        listStyleType: 'none',
        fontSize: '16px',
    };

    const ulStyles = {
        margin: 0,
        padding: 0,
    }

    const handleDownloadClick = () => {
        window.open("https://amazonsales.typeform.com/to/LbHaX0fc", '_blank');
      }
  
    const handleEmailClick = () => {
    const email = "support@grayfalkon.com"; // Replace with the recipient's email address
    const subject = "Customer Request"; // Replace with your desired subject
    const body = ""; // Replace with your desired email body
    
    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    }

    const handleInfoClick = () => {
    window.open("https://grayfalkon.com/analytics-information", '_blank');
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }


    var companyLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/'+defaultGroup.toLowerCase()+'.png'
    var defaultLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/default.png'
    const handleError = (event) => {
        event.target.src = defaultLink;
    };



    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Options Modal"
      >
        <div className={classes.userContainer}>
            <div className={`${classes.sideHeader}`}></div>
              <div className={`${classes.sidebarlogoCircle}`}>
                <img className={classes.sidebarlogo} src={companyLink} onError={handleError}></img>
              </div>
              <div className={`${classes.sideUserInfo}`}>
                <div className={`${classes.sideState}`}>{defaultUser}</div>
                {/* <img
                          className={classes.icon}
                          src={signoutIcon}
                          alt="Signout icon"
                          htmlFor="sign-out"
                          onClick={() => handleLogoutClick()}
                    ></img> */}
              </div>
              
              <div className={`${classes.sidePortalLinks}`}>
                <div className={`${classes.sidePortalContact}`} onClick={() => handleEmailClick()}>
                  <img className={classes.sidebarEmailIcon} src={emailIcon}></img>
                  <div className={`${classes.sideAuxButtons}`}>Email Support</div>
                </div>
                <div className={`${classes.sidePortalDownload}`} onClick={() => handleDownloadClick()}>
                  <img className={classes.sidebarInfoIcon} src={downloadIcon}></img>
                  <div className={`${classes.sideAuxButtons}`}>Request Data</div>
                </div>
                <div className={`${classes.sidePortalInfo}`} onClick={() => handleInfoClick()}>
                  <img className={classes.sidebarInfoIcon} src={infoIcon} ></img>
                  <div className={`${classes.sideAuxButtons}`}>Account Info</div>
                </div>
                <div className={`${classes.sidePortalLogout}`} onClick={() => handleLogoutClick()}>
                  <img className={classes.sidebarLogoutIcon} src={signoutIcon} ></img>
                  <div className={`${classes.sideAuxButtons}`}>Logout</div>
                </div>
              </div>
        </div>
      </Modal>
    );
};

export default UserInfoModal;
import React from 'react';
import Modal from 'react-modal';
import classes from "./ExportModal.module.scss"; // Import custom SCSS styles
import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import excelIcon from "../assets/akar-icons_excel.svg";


import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 

const ExportModal = ({ isOpen, onClose, onExportClick, options, title,  position }) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Ensure the overlay has a high z-index
      backgroundColor: 'rgba(0, 0, 0, 0.0)', // Optional: darken the overlay background
    },
    content: {
      top: `${position.y}px`,
      left: `${position.x}px`,
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-100%, 16%)',
      zIndex: 1001, // Ensure the content has a higher z-index
      borderRadius: '15px', // Curved box
      padding: '20px',
      backgroundColor: '#fff', // White background for the modal content
      color: '#000', // Black text color
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add a shadow for better visibility
    },
  };

  const listStyles = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  };

  const listItemStyles = {
    padding: '0px 0',
    cursor: 'pointer',
    color: '#000', // Explicitly set black text color for list items
    listStyleType: 'none',
    fontSize: '16px',
  };

  const ulStyles = {
    margin: 0,
    padding: 0,
  }

  const handleExportPDFClick = () => {
    onExportClick("PDF")
    //dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-pdf");
  }

  const handleExportCSVClick = () => {
    onExportClick("CSV")
    //dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-csv");
  }

  const handleExportPNGClick = () => {
    onExportClick("PNG")
    //dashboardRef.current.firstChild.childNodes[1].exportImageAsync();
  }

  const handleExportPWRPClick = () => {
    onExportClick("PWRP")
    //dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
  }

  const handleCrossTabClick = () => {
    onExportClick("CROSS")
    //dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");
  }

  return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Options Modal"
      >
        {/* <div className={classes.exportTitle}>Export</div> */}
        <div className={classes.gridContainer}>
            <img
            className={classes.pdficon}
            src={pdfIcon}
            alt="PDF icon"
            htmlFor="download-pdf"
            onClick={() => handleExportPDFClick()}
            ></img>
            <img
            className={classes.pdficon}
            src={pngIcon}
            alt="PNG icon"
            htmlFor="download-png"
            onClick={() => handleExportPNGClick()}
            ></img>
            <img
            className={classes.pwrpicon}
            src={pwrpIcon}
            alt="Power Point icon"
            htmlFor="download-pwrp"
            onClick={() => handleExportPWRPClick()}
            ></img>
            <img
            className={classes.excelicon}
            src={excelIcon}
            alt="Excel icon"
            htmlFor="download-excel"
            onClick={(event) => handleExportCSVClick(event)}
            ></img>
            </div>
      </Modal>
  );
};

export default ExportModal;
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import "react-pro-sidebar/dist/css/styles.css"; // Import default styles
import "./Sidebar.scss"; // Import custom SCSS styles

import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext, useCallback} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/fa-logout.svg";
import menuIcon from "../assets/fa-menu.svg";
import emailIcon from "../assets/fa-email.svg";
import infoIcon from "../assets/fa-info.svg";
import exportIcon from "../assets/fa-export.svg";
import downloadIcon from "../assets/fa-download.svg";

//import reportsIcon from "../assets/fa-reports.svg";
import { ReactComponent as FaReports } from  "../assets/fa-reports.svg";
import { ReactComponent as FaCustom } from  "../assets/fa-custom.svg";
import { ReactComponent as FaAdmin } from  "../assets/fa-admin.svg";


import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import excelIcon from "../assets/akar-icons_excel.svg";


import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import pv_transparent_logo from "../assets/gf_side.png";
import ExportModal from './ExportModal'; // Adjust the path as necessary
import UserInfoModal from './UserInfoModal'; // Adjust the path as necessary


import Modal, {closeStyle} from 'simple-react-modal'
//import 'bootstrap/dist/css/bootstrap.css';

//import Dropdown from 'react-bootstrap/Dropdown';


import Navigation from "./Navigation";

const CustomIcon = (props) => {
  return <FaCustom className={`${classes.sideMenuIcon}`} {...props} />;
};

const ReportsIcon = (props) => {
  return <FaReports className={`${classes.sideMenuIcon}`} {...props} />;
};

const AdminIcon = (props) => {
  return <FaAdmin className={`${classes.sideMenuIcon}`} {...props} />;
};

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Landing = () => {

    
    const [defaultGroup, setDefaultGroup] = useState(JSON.parse(localStorage.getItem("group")) ?? "default");

    const [activeTab, setActiveTab] = useState(0);
    const [menuOpen, setMenuOpen] = useState(isMobileDevice()? false: true);

    const [activeButton, setActiveButton] = useState(0);
    const [activeTopIndex, setActiveTopIndex] = useState(0);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeType, setActiveType] = useState("default");

    const [activeDashboard, setActiveDashboard] = useState(true);
    const [activeURL, setActiveURL] = useState('');
    const [displayTabs, setDisplayTabs] = useState(false);
    const [displayToolbarButtons, setDisplayToolbarButtons] = useState(true);

    const [defultFolder, setDefaultFolder] = useState('Home');
    const [defultFolderId, setDefaultFolderId] = useState('Home');
    const [defaultUser, setDefaultUser] = useState(JSON.parse(localStorage.getItem("user-name")) ?? "");

    //const [breadCrumbs, setBreadCrumbs] = useState([{"id":buttons[0], "name":buttons[0]}]);
    const [currentButtons, setCurrentButtons] = useState([]);
    const [currentSubButtons, setCurrentSubButtons] = useState([]);
    const [currentLinks, setCurrentLinks] = useState([]);


    const [currentCustomButtons, setCurrentCustomButtons] = useState([]);
    const [currentCustomSubButtons, setCurrentCustomSubButtons] = useState([]);
    const [currentCustomLinks, setCurrentCustomLinks] = useState([]);

    
    const [currentAdminButtons, setCurrentAdminButtons] = useState([]);
    const [currentAdminSubButtons, setCurrentAdminSubButtons] = useState([]);
    const [currentAdminLinks, setCurrentAdminLinks] = useState([]);

    //const [currentLinks, setCurrentLinks] = useState(links);
    const [show, setShow] = useState(false);

    //modal stuff
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

    const [modalUserInfoOpen, setModalUserInfoOpen] = useState(false);
    const [modalUserInfoPosition, setModalUserInfoPosition] = useState({ x: 0, y: 0 });

    //const [dashboardRef, setDashboardRef] = useState(undefined)
    const dashboardRef = useRef(null)

    const validUserContext = useContext(ValidUserContext);

    useEffect(() => {
      var currentNav = Object.entries(JSON.parse(localStorage.getItem("navigation")));
      var filteredNav = currentNav.filter(([a, b]) => {
        return b.client.includes("Xeomatrix_Main Template")
      });

      var filteredCustomNav = currentNav.filter(([a, b]) => {
        return  b.client.includes(defaultGroup) 
      });

      var filteredAdminNav = currentNav.filter(([a, b]) => {
        return b.client.includes("Admin")
      });

      var sortedNav = filteredNav.sort((a, b) => {
        if(a[1].client.includes("Xeomatrix_Main Template") || b[1].client.includes("Xeomatrix_Main Template") ){
          if(a[1].client.includes("Xeomatrix_Main Template")) {
            // If two elements have same number, then the one who has larger rating.average wins
            return -1;
          } else {
            // If two elements have different number, then the one who has larger number wins
            return 1;
          }
        } else {
            if(a[1].name > b[1].name) {
              // If two elements have same number, then the one who has larger rating.average wins
              return 1;
            } else {
              // If two elements have different number, then the one who has larger number wins
              return -1;
            }
        }
      });

      var buttons = Object.keys(filteredNav).map(key => filteredNav[key][1].name)
      var subButtons = Object.keys(filteredNav).map(key => filteredNav[key][1].dashboard_names)
      var links = Object.keys(filteredNav).map(key => filteredNav[key][1].dashboards)

      var custombuttons = Object.keys(filteredCustomNav).map(key => filteredCustomNav[key][1].name)
      var customsubButtons = Object.keys(filteredCustomNav).map(key => filteredCustomNav[key][1].dashboard_names)
      var customlinks = Object.keys(filteredCustomNav).map(key => filteredCustomNav[key][1].dashboards)

      var customadminbuttons = Object.keys(filteredAdminNav).map(key => filteredAdminNav[key][1].name)
      var customadminsubButtons = Object.keys(filteredAdminNav).map(key => filteredAdminNav[key][1].dashboard_names)
      var customdaminlinks = Object.keys(filteredAdminNav).map(key => filteredAdminNav[key][1].dashboards)

      setCurrentButtons(buttons)
      setCurrentSubButtons(subButtons)
      setCurrentLinks(links)

      setCurrentCustomButtons(custombuttons)
      setCurrentCustomSubButtons(customsubButtons)
      setCurrentCustomLinks(customlinks)

      setCurrentAdminButtons(customadminbuttons)
      setCurrentAdminSubButtons(customadminsubButtons)
      setCurrentAdminLinks(customdaminlinks)

      setActiveURL(links[0][0])
    }, [defaultGroup]);

    const handleDashboardClick = (link) => {
        setActiveDashboard(true)
        setActiveURL(link)
        setDisplayTabs(false)
        setDisplayToolbarButtons(true)
    }

    const handleFolderClick = (name, id) => {
      setDefaultFolder(name)
      setDefaultFolderId(id)
      //breadCrumbs.push({"id":id, "name":name})
      //setBreadCrumbs(breadCrumbs)
    }

    const handleDownloadClick = () => {
      window.open("https://amazonsales.typeform.com/to/LbHaX0fc", '_blank');
    }

    const handleEmailClick = () => {
      const email = "support@grayfalkon.com"; // Replace with the recipient's email address
      const subject = "Customer Request"; // Replace with your desired subject
      const body = ""; // Replace with your desired email body
    
      // Construct the mailto link
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }

    const handleInfoClick = () => {
      window.open("https://grayfalkon.com/analytics-information", '_blank');
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }

    const handleExportClick = (type) => {
      setModalOpen(false);
      if(type == "PDF") {
        dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-pdf");
      } else if (type == "PNG") {
        dashboardRef.current.firstChild.childNodes[1].exportImageAsync();
      } else if (type == "EXCEL") {
        dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-csv");
      } else if (type == "PWRP") {
        dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
      } else if (type == "CSV") {
        dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");
      }
    }


    const handleLogoClick = () => {
      setActiveDashboard(false)
    }

    const handleButtonClick = (topIndex, tabIndex, tabText) => {
      validUserContext.localAuthCheck();
      if(topIndex != activeTopIndex || activeType != "default") {
        setActiveURL(currentLinks[topIndex][tabIndex])
      } else {
        console.log(currentSubButtons[topIndex][tabIndex].match(/[^/]+$/)[0])
        var selectedSheet = dashboardRef.current.firstChild.childNodes[1].workbook.publishedSheetsInfo.filter((sheets) => sheets.name == currentSubButtons[topIndex][tabIndex])
        if (selectedSheet.length >0) {
          dashboardRef.current.firstChild.childNodes[1].workbook.activateSheetAsync(selectedSheet[0].name)
        }else {
          setActiveURL(currentLinks[topIndex][tabIndex])
        }
      }
      setActiveTopIndex(topIndex);
      setActiveTabIndex(tabIndex);
      setActiveDashboard(true);
      setDefaultFolder('Home');
      setDisplayTabs(false);
      setActiveType("default");
      //setMenuOpen(false);
      setDisplayToolbarButtons(true);

    };

    const handleCustomButtonClick = (topIndex, tabIndex, tabText) => {
      validUserContext.localAuthCheck();
      if(topIndex != activeTopIndex || activeType != "custom") {
        setActiveURL(currentCustomLinks[topIndex][tabIndex])
      } else {
        console.log(currentCustomSubButtons[topIndex][tabIndex].match(/[^/]+$/)[0])
        var selectedSheet = dashboardRef.current.firstChild.childNodes[1].workbook.publishedSheetsInfo.filter((sheets) => sheets.name == currentCustomSubButtons[topIndex][tabIndex])
        if (selectedSheet.length >0) {
          dashboardRef.current.firstChild.childNodes[1].workbook.activateSheetAsync(selectedSheet[0].name)
        }else {
          setActiveURL(currentCustomLinks[topIndex][tabIndex])
        }
      }
      setActiveTopIndex(topIndex);
      setActiveTabIndex(tabIndex);
      setActiveDashboard(true);
      setDefaultFolder('Home');
      setDisplayTabs(false);
      setActiveType("custom");
      //setMenuOpen(false);
      setDisplayToolbarButtons(true);
    };

    const handleAdminButtonClick = (topIndex, tabIndex, tabText) => {
      validUserContext.localAuthCheck();
      if(topIndex != activeTopIndex || activeType != "admin") {
        setActiveURL(currentAdminLinks[topIndex][tabIndex])
      } else {
        console.log(currentAdminSubButtons[topIndex][tabIndex].match(/[^/]+$/)[0])
        var selectedSheet = dashboardRef.current.firstChild.childNodes[1].workbook.publishedSheetsInfo.filter((sheets) => sheets.name == currentAdminSubButtons[topIndex][tabIndex])
        if (selectedSheet.length >0) {
          dashboardRef.current.firstChild.childNodes[1].workbook.activateSheetAsync(selectedSheet[0].name)
        }else {
          setActiveURL(currentAdminLinks[topIndex][tabIndex])
        }
      }
      setActiveTopIndex(topIndex);
      setActiveTabIndex(tabIndex);
      setActiveDashboard(true);
      setDefaultFolder('Home');
      setDisplayTabs(false);
      setActiveType("admin");
      //setMenuOpen(false);
      setDisplayToolbarButtons(true);
    };

    // const handleBreadCrumbClick = (folderName) => {
    //   setActiveDashboard(false)
    //   setDefaultFolder(folderName.name)
    //   setDefaultFolderId(folderName.id)

    //   let indexToRemove = breadCrumbs.indexOf(folderName);
    //   let newBreadCrumbs
    //   // Remove elements after the specific element
    //   if (indexToRemove !== -1) {
    //     newBreadCrumbs = breadCrumbs.slice(0, indexToRemove + 1);
    //   }
    //   setBreadCrumbs(newBreadCrumbs)
    // }

    const handleMenuClick  = () => {
      if (menuOpen) {
        setMenuOpen(false)
      } else {
        setMenuOpen(true)
      }
    };

    const handleCloseModal = (option) => {
      setModalOpen(false);
    };


    const handleCloseUserInfoModal = (option) => {
      setModalUserInfoOpen(false);
    };

    const handleOpenExportModal = (event) => {
      setModalPosition({ x: event.clientX, y: event.clientY });
      setModalOpen(true);
    };


    const handleOpenUserInfoModal = (event) => {
      setModalUserInfoPosition({ x: event.clientX, y: event.clientY });
      setModalUserInfoOpen(true);
    };


    const renderContent = () => {
      if (activeDashboard)
          return renderDashboard()
      else 
          return renderNavigation()
    } 

    const renderNavigation = () => {
      return (
        <div>
            <Navigation defaultFolder={defultFolder} defaultFolderId={defultFolderId} onDashboardClick={handleDashboardClick} onFolderClick={handleFolderClick}></Navigation>
        </div>
      )
    }

    async function getSelectedMarks(event) {
      const filterSelected = await event.detail.getFilterAsync();
      if (filterSelected.fieldName == "Tableau Group" && filterSelected.appliedValues.length>0) {
        const valueSelected = filterSelected.appliedValues[0].value;          
        console.log(`${valueSelected}  Selected`);
        setDefaultGroup(valueSelected)
      } else if (filterSelected.fieldName == "Tableau Group"){
        setDefaultGroup("default")
      }
    }


    const renderDashboard = () => {
      // Check if both activeURL and displayTabs are not null
      if (activeURL && displayTabs != null) {
        return (
            <Dashboard defaultGroup={defaultGroup} dashboardLinkProp={activeURL} displayTabs={displayTabs} filterChanged={(event) => getSelectedMarks(event)}/>
        );
      }
    
      // Optionally, return null or some fallback content if the parameters are null
      return null;
    };
    
    
    const rendeToolbar = () => {  
      
    }
    
    
    const renderButtons = () => {  
      return currentButtons.map((buttonText, index) => {
        if (activeButton === index) {
            return (<div className={`${classes.sideButton} ${classes.active}`} onClick={() => handleButtonClick(index, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        } else {
            return (<div className={`${classes.sideButton}`} onClick={() => handleButtonClick(index, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        }
      });
    };

    // const renderDrowpdowns = () => {  
    //   return (
    //     <ProSidebar>
    //       <Menu iconShape="square">
    //       {currentButtons.map((buttonText, menuIndex) => (
    //           <SubMenu className={`${classes.sideButtonTop} ${menuIndex === activeTopIndex ? classes.active : ''}`} title={buttonText} defaultOpen={true}>
    //           {currentSubButtons[menuIndex].map((buttonText, index) => (
    //               <MenuItem className={`${classes.sideButtonTab} ${index === activeTabIndex && menuIndex === activeTopIndex ? classes.active : ''}`} onClick={() => handleButtonClick(menuIndex, index,  buttonText)}>{buttonText.replace("ReportingPortal", 'Overview').match(/[^/]+$/)[0].replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</MenuItem>
    //           ))}   
    //           </SubMenu>
    //       ))}        
    //       </Menu>
    //     </ProSidebar>
    //   )
    // };

    const renderDrowpdowns = () => {  
      return (
        <ProSidebar>
          <Menu iconShape="square">
          {currentButtons.map((buttonText, menuIndex) => (
              <SubMenu className={`${classes.sideButtonTop} ${menuIndex === activeTopIndex && activeType == "default" ? classes.active : ''}`} title={buttonText} defaultOpen={true} icon={<ReportsIcon />}>
              {currentSubButtons[menuIndex].map((buttonText, index) => (
                  <MenuItem className={`${classes.sideButtonTab} ${index === activeTabIndex && activeType == "default" && menuIndex === activeTopIndex ? classes.active : ''}`} onClick={() => handleButtonClick(menuIndex, index,  buttonText)}>{buttonText.replace("ReportingPortal", 'Overview').match(/[^/]+$/)[0].replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</MenuItem>
              ))}   
              </SubMenu>
          ))}
          {currentCustomButtons.length > 0 && (
            <SubMenu className={`${classes.sideButtonTop}`} title={"Custom Reports"} defaultOpen={false} icon={<CustomIcon />}> 
            {currentCustomButtons.map((buttonText, menuIndex) => (
                  <SubMenu className={`${classes.sideButtonTop} ${menuIndex === activeTopIndex && activeType == "custom" ? classes.active : ''}`} title={buttonText} defaultOpen={true}>
                  {currentCustomSubButtons[menuIndex].map((buttonText, index) => (
                      <MenuItem className={`${classes.sideButtonTab} ${index === activeTabIndex && activeType == "custom"  && menuIndex === activeTopIndex ? classes.active : ''}`} onClick={() => handleCustomButtonClick(menuIndex, index,  buttonText)}>{buttonText.replace("ReportingPortal", 'Overview').match(/[^/]+$/)[0].replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</MenuItem>
                  ))}   
                  </SubMenu>
            ))}
            </SubMenu>    
          )}
          {currentAdminButtons.length > 0 && (
            <SubMenu className={`${classes.sideButtonTop}`} title={"Admin Reports"} defaultOpen={false} icon={<AdminIcon />}> 
            {currentAdminButtons.map((buttonText, menuIndex) => (
                  <SubMenu className={`${classes.sideButtonTop} ${menuIndex === activeTopIndex && activeType == "admin" ? classes.active : ''}`} title={buttonText} defaultOpen={true}>
                  {currentAdminSubButtons[menuIndex].map((buttonText, index) => (
                      <MenuItem className={`${classes.sideButtonTab} ${index === activeTabIndex && activeType == "admin"  && menuIndex === activeTopIndex ? classes.active : ''}`} onClick={() => handleAdminButtonClick(menuIndex, index,  buttonText)}>{buttonText.replace("ReportingPortal", 'Overview').match(/[^/]+$/)[0].replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</MenuItem>
                  ))}   
                  </SubMenu>
            ))}
            </SubMenu>    
          )}             
          </Menu>
        </ProSidebar>
      )
    };


    // const renderDrowpdowns = () => {  
    //   return (
    //     <ProSidebar>
    //       <Menu iconShape="square">
    //         <SubMenu className={`${classes.sideButtonTop} ${classes.active}`} title="Dashboard Hub">
    //           <MenuItem className={`${classes.active}`}>Component 1</MenuItem>
    //           <MenuItem>Component 2</MenuItem>
    //         </SubMenu>
    //         <SubMenu className={`${classes.sideButtonTop}`} title="Admin">
    //           <MenuItem>View 1</MenuItem>
    //           <MenuItem>View 2</MenuItem>
    //         </SubMenu>          
    //       </Menu>
    //     </ProSidebar>
    //   )
    // };
    const handleShow = () => {
      setShow(true)
    };
   
    const handleClose = () => {
      setShow(false)
    };


    var companyLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/'+defaultGroup.toLowerCase()+'.png'
    var defaultLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/default.png'
    const handleError = (event) => {
      event.target.src = defaultLink;
    };

    const renderFull = () => {
      return (
          <div className={`${classes.landing}`}>

          <div className={`${menuOpen ? classes.sidebar : classes.sidebarClosed}`}>  
            {menuOpen ?
            (
            <div>
              <div className={`${classes.sidebarlogo}`}/>
              <div className={`${classes.sidePortalName}`}>
                <img className={classes.sidebarlogo} src={pv_transparent_logo}></img>
              </div> 
              {renderDrowpdowns()}
            </div>
            ):
            (
              <div className={classes.sidebarArrowContainer} onClick={() => handleMenuClick()}>
                <div className={classes.sidebarArrow} onClick={() => handleMenuClick()}></div>
              </div>  
            )
            }

            {/* <div className={`${classes.sidePortalName}`}>
              <img className={classes.sidebarlogo} src={pv_transparent_logo}></img>
            </div> */}
          </div>
          <div className={`${menuOpen ? classes.contentblock : classes.contentblockMobile}`}>
            <div className={`${classes.toolbar}`}>
                {/* <img
                      className={classes.icon}
                      src={signoutIcon}
                      alt="Signout icon"
                      htmlFor="sign-out"
                      onClick={() => handleLogoutClick()}
                ></img>
                <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span> */}
                {displayToolbarButtons? 
                (
                  <div>
                      <img
                      className={classes.companyicon}
                      src={companyLink}
                      alt="Excel icon"
                      htmlFor="download-excel"
                      onClick={(event) => handleOpenUserInfoModal(event)}
                      ></img>
                      <img
                      className={classes.dividericon}
                      src={dividerIcon}
                      alt="Divider icon"
                      htmlFor="divider"
                      ></img>
                      <img
                      className={classes.downloadicon}
                      src={exportIcon}
                      alt="Excel icon"
                      htmlFor="download-excel"
                      onClick={(event) => handleOpenExportModal(event)}
                      ></img>

                      {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Dropdown Button
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}

                      <span className={classes.titleText}>Marketplace Brand Protection Portal</span>
                      <img
                        className={`${menuOpen ? classes.menuiconOpen : classes.menuicon}`}
                        src={menuIcon}
                        alt="Menu icon"
                        htmlFor="menu-click"
                        onClick={() => handleMenuClick()}
                      ></img>
                      {/* <img
                      className={classes.titleLogo}
                      src={pv_transparent_logo}
                      alt="PDF icon"
                      htmlFor="download-pdf"
                      onClick={() => handleDownloadClick()}
                      ></img> */}
                  </div>
                ):(
                  <div></div>
                )
                }
                
                {/* {breadCrumbs.map((folderName) => (
                  <div className={`${classes.breadcrumb}`}>{folderName.name} &gt;</div>
                ))} */}
            </div>
            <div className={classes.dashboardblock} ref={dashboardRef} >
                {renderContent()}
            </div>
            <ExportModal
                isOpen={modalOpen}
                onClose={handleCloseModal}
                onExportClick={handleExportClick}
                options=""
                title=""
                position={modalPosition}
            />
            <UserInfoModal
                isOpen={modalUserInfoOpen}
                onClose={handleCloseUserInfoModal}
                companyLink={companyLink}
                defaultGroup={defaultGroup}
                defaultUser={defaultUser}
                title=""
                position={modalUserInfoPosition}
            />
          </div>
          
        </div>
      )
    }

    // var jwtToken = JSON.parse(localStorage.getItem("tableau-login-data"));
    // localStorage.setItem("tableau-login-data", JSON.stringify("redeemed"));

    // var inputProps = {
    // };
    
    // if (jwtToken != "redeemed") {
    //   inputProps.token = jwtToken;
    // }
    var dashboardURL = JSON.parse(localStorage.getItem("dashboard-url"));
    dashboardURL = dashboardURL + "ACA-Travel/TravelMediaPerformance" + '?:showVizHome=no&:embed=true&:toolbar=no&:tabs=n'

    return (
      <div>
          {renderFull()}
      </div>
    );
  };
  
  export default Landing;
